import { useParams } from 'react-router-dom';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { useCache } from 'src/hooks/useCache';
import { useMoveObject } from 'src/api/objects';
import { useDrag, useDrop } from 'react-dnd';
import React, { useCallback } from 'react';
import { FileType, FolderType } from 'src/types';
import { TableItemType } from 'src/components/Table/types';
import { useTranslation } from 'react-i18next';

export const useRowState = (
  item: any,
  onRowClick?: (item: TableItemType) => void,
  disableDrag?: boolean,
) => {
  const { t } = useTranslation();
  const { diskId, folderId } = useParams();
  const parent_id = folderId || diskId || '';

  const {
    updateState,
    isSelected,
    selectedIdsRef,
    filesRef,
    foldersRef,
    selectedIds,
    toggleSection,
    isGroupDragging,
    clearSelectedIds,
  } = useDragState();

  const { invalidateCache, removeFile, removeFolder } = useCache(parent_id);
  const { mutateAsync: moveFile } = useMoveObject();
  const file = item.type !== t('folderWithFiles') ? item : null;
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'file',
    item: () => {
      updateState({ isDragging: true });
      return {
        files: isSelected(selectedIdsRef.current, item.id)
          ? filesRef.current.map((f) => ({ ...f, parent_id }))
          : [...filesRef.current, file].map((f) => ({ ...f, parent_id })),
        folders: foldersRef.current,
      };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging() && !disableDrag,
    }),
    end: () => updateState({ isDragging: false }),
  }));

  const onDropHandler = async (ids: string[]) => {
    if (ids.length === 0) return;
    const response = await moveFile({
      destination_id: item.id,
      item_ids: ids,
    });
    if (response.success) {
      ids.forEach((id) => {
        removeFile(id);
        removeFolder(id);
      });
      clearSelectedIds();
    }
    invalidateCache();
  };

  const onDrop = useCallback(
    (data: { files: FileType[]; folders: FolderType[] }) => {
      const ids = data.files
        .filter((f) => f.parent_id !== item.id)
        .map((f) => f.id)
        .concat(data.folders.filter((f) => f.parent_id !== item.id).map((f) => f.id));
      onDropHandler(ids);
    },
    [item, selectedIds, clearSelectedIds],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ['file', 'folder'],
      drop: onDrop,
      canDrop: (item, monitor) => {
        return monitor.isOver({ shallow: true }) && !disableDrag;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }) && !disableDrag,
        canDrop: monitor.canDrop(),
      }),
    }),
    [item, parent_id, selectedIds],
  );

  const onRowClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isDragging) {
      return;
    }
    if (selectedIds.length > 0) {
      toggleSection(item.id);
      return;
    }
    onRowClick && onRowClick(item);
  };

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.ctrlKey || event.metaKey) {
      toggleSection(item.id);
    }
  };

  return {
    drag,
    preview,
    drop,
    isDragging,
    isOver,
    canDrop,
    onRowClickHandler,
    onContextMenu,
    updateState,
    isSelected,
    selectedIdsRef,
    filesRef,
    foldersRef,
    selectedIds,
    toggleSection,
    isGroupDragging,
    clearSelectedIds,
  };
};
