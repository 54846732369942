import React, { FC } from 'react';
import { StarSmallIcon } from 'src/components/Icons';
import { FileMedia } from 'src/components/FileMedia';
import { FileType } from 'src/types';
import './styles.scss';

type Props = {
  file: FileType;
};
export const FileIcon: FC<Props> = ({ file }) => {
  return (
    <div className="file-icon">
      {file.is_favorite && (
        <div className="file-icon__favorite">
          <StarSmallIcon />
        </div>
      )}
      <div className="file-icon__media-wrapper">
        <FileMedia
          fileId={file.thumbnails ? file.thumbnails['500x500'].key : null}
          extension={file?.extension as string}
          className="file-icon__file-icon"
        />
      </div>
    </div>
  );
};
