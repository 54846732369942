import React, { FC, memo, useEffect, useRef } from 'react';
import {
  CreateFilePayload,
  Etag,
  useCompleteMultiPart,
  useCreateFile,
  useCreateMultiPart,
  useUploadPart,
} from 'src/api/fileUploads';
import { FileType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { useMoveToTrash } from 'src/api/trash';
import './styles.scss';
import { UploadFileType } from 'src/context/GlobalContext';
import { usePrevious } from 'src/hooks/usePrevious';
import { isEqual } from 'lodash';

type Props = {
  file: UploadFileType;
  onFinish: (file: FileType, f?: UploadFileType) => void;
  onCancel: (file: UploadFileType) => void;
  onStart: (file: UploadFileType) => void;
  onRetry: () => void;
  onUploading: (progress: number, bytes: number) => void;
  diskId: string;
  folderId: string;
  onError: (error: string) => void;
};

const FileUploader: FC<Props> = ({
  file,
  onFinish,
  onCancel,
  onRetry,
  diskId,
  folderId,
  onUploading,
  onStart,
  onError,
}) => {
  const { t } = useTranslation();
  const fileId = useRef<string>('');

  const cancelUpload = useRef<boolean>(false); // Ref to track cancel status
  const pausedUpload = useRef<boolean>(false); // Ref to track pause status
  const abortController = useRef<AbortController | null>(null); // Ref to track the abort controller

  const chunkSize = 5242880; //1024 * 1024 * 6; // 6MB chunk size

  const { mutateAsync: deleteFile } = useMoveToTrash();
  const { mutateAsync: createFile } = useCreateFile();
  const { mutateAsync: createPart } = useCreateMultiPart();
  const { mutateAsync: uploadPart } = useUploadPart();
  const { mutateAsync: completeUploading } = useCompleteMultiPart();

  const splitStringIntoChunks = (file: File, n: number) => {
    const result: Blob[] = [];
    for (let i = 0; i < file.size; i += n) {
      result.push(file.slice(i, i + n));
    }
    return result;
  };

  useEffect(() => {
    if (!file.isStarted) {
      (async () => {
        await handleUpload(file);
      })();
      onStart(file);
    }
  }, []);

  const onUploadingHandler = (progress: number, bytes: number) => {
    onUploading(progress, bytes);
  };

  const onFinishHandler = (f: FileType, fl?: UploadFileType) => {
    onFinish(f, fl);
  };

  const handleUpload = async (fileData: UploadFileType) => {
    if (!fileData || !diskId) {
      return;
    }

    const selectedFile = fileData.file;
    const totalChunks = Math.ceil(fileData.fileSize / chunkSize);

    try {
      const createFilePayload: CreateFilePayload = {
        parent_id: folderId || diskId,
        name: fileData.fileName,
        size: fileData.fileSize,
      };

      const createFileResponse = await createFile(createFilePayload);

      if (!createFileResponse.success) {
        onError(createFileResponse.error);
        return;
      }
      fileId.current = createFileResponse.file.id;

      const createPartResponse = await createPart(fileId.current);
      if (!createPartResponse.success) {
        onError(createPartResponse.error);
        return;
      }

      const uploadId = createPartResponse.uploadId;
      const tags: Etag[] = [];

      const chunks = splitStringIntoChunks(selectedFile, chunkSize);

      for (let chunkIndex = 1; chunkIndex <= totalChunks; chunkIndex++) {
        if (cancelUpload.current) {
          //onError('Upload canceled');
          return;
        }

        while (pausedUpload.current) {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }

        const chunk = chunks[chunkIndex - 1];
        abortController.current = new AbortController(); // Create a new AbortController for each request

        const uploadPartResponse = await uploadPart({
          payload: {
            key: fileId.current,
            uploadId: uploadId,
            partNumber: chunkIndex,
            data: chunk,
          },
          controller: abortController.current,
        });

        if (uploadPartResponse.success) {
          onUploadingHandler((chunkIndex / totalChunks) * 100, chunk.size);
          tags.push({
            PartNumber: chunkIndex,
            ETag: uploadPartResponse.data.ETag,
          });
        } else {
          const error = uploadPartResponse.error;
          // onError(error === 'aborted' ? t('canceled') : error);
          return;
        }
      }

      const completeResponse = await completeUploading({
        key: fileId.current,
        uploadId: uploadId,
        archive_id: diskId,
        data: tags,
      });

      if (!completeResponse.success) {
        onError('Failed to complete multipart upload.');
        return;
      }

      onFinishHandler(createFileResponse.file, fileData);
    } catch (error) {
      onError(error as string);
    }
  };

  const handleCancel = async () => {
    onCancel({ ...file, isCanceled: true, progress: 0 });
    pausedUpload.current = false;
    cancelUpload.current = true;
    if (abortController.current) {
      abortController.current.abort();
    }
    await deleteFile({
      item_ids: [fileId.current],
    });
  };

  const onRetryHandler = async () => {
    onRetry();
    cancelUpload.current = false;
    pausedUpload.current = false;
    await handleUpload(file);
  };

  const prevFile = usePrevious(file);
  useEffect(() => {
    if (isEqual(prevFile, file)) return;
    pausedUpload.current = !!file.isPaused;
    if (file.isCanceled) {
      handleCancel();
      return;
    }
  }, [file]);

  useEffect(() => {
    if (file.isRetrying) {
      onRetryHandler();
    }
  }, [file.isRetrying]);

  return <></>;
};

export default memo(FileUploader);
