import { useLoadDisks2 } from 'src/api/disks';
import { DiskType } from 'src/types';

export const useGetDisks = () => {
  const { data, isLoading, ...rest } = useLoadDisks2({});
  const disks: DiskType[] = data?.disks || [];
  return {
    disks,
    isLoading,
    ...rest,
  };
};
