import React, { FC } from 'react';
import { ReactComponent as CardsIcon } from '../../assets/cards-icon.svg';
import './styles.scss';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Button } from 'src/components/Forms/Button';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
};

export const PaymentBlock: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('payment-block', className)}>
      <div className="payment-block__content">
        <div className="payment-block__title mb-15">
          <CardsIcon className="payment-block__title-icon" /> <span>{t('paymentAmount')}</span>
        </div>
        <div className="payment-block__inner-content">
          <div className="mb-15">
            <Input placeholder="$0" className="payment-block__input" />
          </div>
          <div className="mb-15">
            <Button type="primary" content="OK" fullWidth />
          </div>
          <div className="mb-15">
            <Button type="text" content={t('cancel')} fullWidth />
          </div>
        </div>
      </div>
    </div>
  );
};
