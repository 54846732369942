import React, { FC, useState } from 'react';
import { CloseIcon } from 'src/components/Icons';
import { DiskObject } from 'src/components/DiskObject';
import { emptyDisk } from 'src/dataMocks';
import { DiskColorType, DiskDetailsType, DiskType, DriveTypes } from 'src/types';
import { EmptyBlock } from 'src/components/EmptyBlock';
import { Input } from 'src/components/Forms/Inputs/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DiskParameters } from 'src/components/CreateDisk/DiskParameters';
import { DiskDesign } from './DiskDesign';
import { DiskCapacity } from './DiskCapacity';
import { DiskInvoice } from 'src/components/CreateDisk/DiskInvoice';
import { Button } from 'src/components/Forms/Button';
import { ValuesType } from 'src/components/CreateDisk/types';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { DiskConfirmation } from 'src/components/CreateDisk/DiskConfirmation';
import './styles.scss';
import { useGetDiskDetails } from 'src/api/disks';

type Props = {
  onCLose: () => void;
  parameters?: Partial<DiskType> & { invoice?: string };
};

export const CreateDisk: FC<Props> = ({ onCLose, parameters }) => {
  const minSize = 25000000000;
  const maxSize = 10000000000000;

  const [totalPrice, setTotalPrice] = useState<string>('0.00');
  const { openModal } = useAppContext();
  const [serverError, setServerError] = useState<string>('');
  const { t } = useTranslation();

  const invoice = parameters?.invoice || 'monthly';

  const { data: details } = useGetDiskDetails(parameters?.id || '');
  const diskDetails: DiskDetailsType = details?.data || {};

  const initialValues: ValuesType = {
    name: 'New disk',
    color: 'blue',
    ...parameters,
    drive_type: (diskDetails.storage_type || 'standard') as DriveTypes,
    invoice: invoice === 'monthly' ? 'monthly' : 'ANNUAL',
    filled_volume: parameters?.total_volume || minSize,
  };

  const data: DiskType = {
    ...emptyDisk,
    name: 'New disk',
    total_volume: maxSize,
    filled_volume: 5000000000,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(50, ' ').required(' '),
  });

  const handleSaveChanges = async (values: ValuesType) => {
    openModal({
      content: (
        <DiskConfirmation
          values={values}
          onBackClick={() => {
            openModal({ open: false });
          }}
          totalPrice={totalPrice}
          disk={parameters}
          diskDetails={diskDetails}
        />
      ),
      title: t('uploadFiles'),
      className: 'disk-confirmation-modal',
      width: '840px',
      hideHeader: true,
    });
  };

  return (
    <div className="create-disk">
      <div className="right-title">
        <span>{parameters?.id ? t('editDisk') : t('createANewDisk')}</span>
        <CloseIcon className="right-title__close" onClick={onCLose} />
      </div>
      <div className="create-disk__content-wrapper">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSaveChanges}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ submitForm, values, setFieldValue, errors, isValid }) => {
            return (
              <div className="create-disk__content">
                <DiskObject
                  disk={{
                    ...data,
                    name: values.name,
                    filled_volume: values.filled_volume,
                    color: values.color as DiskColorType,
                  }}
                />
                <EmptyBlock>
                  <div className="create-disk__server">AWS Storage server</div>
                </EmptyBlock>
                <EmptyBlock>
                  <div className="create-disk__row">
                    <Input
                      label={`${t('diskName')}:`}
                      placeholder={t('diskName')}
                      value={values.name}
                      onChange={async (value) => {
                        await setFieldValue('name', value);
                        setServerError('');
                      }}
                      error={errors.name || serverError}
                      maxLength={50}
                      name="name"
                    />
                  </div>
                </EmptyBlock>
                <EmptyBlock>
                  <div className="create-disk__row">
                    <DiskParameters
                      value={values.drive_type}
                      onChange={async (value) => {
                        await setFieldValue('drive_type', value);
                      }}
                      isDisabled={!!parameters?.id}
                    />
                  </div>
                </EmptyBlock>
                <DiskDesign
                  color={values.color}
                  onChange={async (v) => {
                    await setFieldValue('color', v);
                  }}
                />
                <EmptyBlock>
                  <DiskCapacity
                    onChange={async (v) => {
                      await setFieldValue('filled_volume', v);
                    }}
                    capacity={values.filled_volume}
                    /* min={parameters?.id ? parameters?.total_volume || minSize : minSize}*/
                    min={minSize}
                    max={maxSize}
                  />
                </EmptyBlock>
                <EmptyBlock>
                  <DiskInvoice
                    values={values}
                    onChange={async (v) => {
                      await setFieldValue('invoice', v);
                    }}
                    onPriceChange={(price) => {
                      setTotalPrice(price);
                    }}
                  />
                </EmptyBlock>
                <Button type="primary" onClick={submitForm} content="Ok" isDisabled={!isValid} />
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
