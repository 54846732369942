import { API } from 'src/utils/API';
import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { objectToQueryParams } from 'src/helpers';
import { FileType, FolderType } from 'src/types';
import { sortFiles } from 'src/helpers/sort';
import { updateCache, updateGlobalCache } from 'src/api/ cacheUtils';
import { RequestPayload } from 'src/types/general';
import { useEffect, useState } from 'react';
import { usePrevious } from 'src/hooks/usePrevious';

export type FilesPayload = RequestPayload & {
  sort?: string;
  type?: string;
};

interface FetchDataResponse {
  success: boolean;
  files: File[];
  otherData?: Record<string, any>;
}

export const useGetFilesInfinity = (payload: FilesPayload, isDisabled = false) => {
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const queryKey = ['load-files', payload]; // Add type explicitly to queryKey

  const { parent_id, limit, offset, type, ...rest } = payload;

  const fetchData = async ({ pageParam = offset }): Promise<FetchDataResponse> => {
    const params = {
      ...rest,
      limit,
      offset: pageParam,
      extension_type: type !== 'all' ? type : undefined, // Add type filtering logic
    };
    const resp = await API.get(
      `/resources/${parent_id}/files?${objectToQueryParams(params)}`,
      'archive',
    );

    if (resp.success) {
      return {
        success: true,
        files: resp.data.items,
        ...resp.otherData,
      };
    } else {
      return {
        success: false,
        files: [],
      };
    }
  };

  const prevType = usePrevious(type);
  useEffect(() => {
    if (prevType !== type) {
      setIsFilterChanged(true);
    } else {
      setIsFilterChanged(false);
    }
  }, [type]);

  const resp = useInfiniteQuery({
    queryKey, // Use the updated queryKey with type included
    queryFn: fetchData,
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.files.length > 0 ? allPages.length * limit : undefined;
    },
    enabled: !isDisabled && !document.location.pathname.includes('trash'),
    select: (data: any) => {
      const allFiles = data.pages.flatMap((page: any) => page.files);
      const otherData = data.pages[0]?.otherData || {};
      return {
        files: allFiles,
        ...otherData,
      };
    },
    staleTime: isFilterChanged ? 0 : 1000 * 60 * 5,
  } as any);
  return resp;
};

export const useGetFiles = (initialPayload: FilesPayload, isDisabled = false) => {
  const [payload, setPayload] = useState(initialPayload);
  const [files, setFiles] = useState<FileType[]>([]);
  useEffect(() => {
    setFiles([]);
  }, [initialPayload.parent_id]);

  const queryKey = ['load-files', payload];

  const { parent_id, ...rest } = payload;

  const params = {
    ...rest,
  };

  const fetchData = async () => {
    const resp = await API.get(
      `/resources/${parent_id}/files?${objectToQueryParams(params)}`,
      'archive',
    );
    if (resp.success) {
      setFiles((prevFiles) => [...prevFiles, ...(resp.data.items as FileType[])]);
      return {
        success: true,
        files: files,
      };
    } else {
      return resp;
    }
  };

  const { data, ...query } = useQuery({
    queryKey: queryKey,
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5,
    enabled: !!payload.parent_id && !isDisabled,
    retry: 0,
  });

  const loadMore = (newOffset: number) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      offset: newOffset,
    }));
  };

  const invalidateCache = () => {
    query.refetch();
  };

  return {
    data: files,
    ...query,
    loadMore,
    invalidateCache,
  };
};

export const useFileImg = (avatar_key: string | null) => {
  const fetchData = async () => {
    const resp = await API.getAbs(
      `${process.env.REACT_APP_API_PROTOCOL}://file.${process.env.REACT_APP_API_URL}/thumbnails?key=${avatar_key}`,
    );

    if (resp.success) {
      return {
        success: true,
        image: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useQuery({
    queryKey: ['load-avatar', avatar_key],
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5,
    enabled: avatar_key !== null,
    retry: 0,
  });
};

export const useFileCache = (payload: Partial<FilesPayload>) => {
  const queryKey = ['load-files', payload];
  const queryClient = useQueryClient();

  const getFilesFoldersFromCache = () => {
    let files: FileType[] = [];
    let folders: FolderType[] = [];
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.pages) {
        oldData.pages.forEach((page: any) => {
          if (page.files) {
            files = [...files, ...page.files];
          }
        });
      }
      if (oldData && oldData.folders) {
        folders = [...folders, ...oldData.folders];
      }
    });
    return { files, folders };
  };

  const addFileCache = (file: FileType) => {
    updateCache(queryClient, queryKey, (oldData) => {
      if (oldData && oldData.pages) {
        return {
          ...oldData,
          pages: oldData.pages.map((page: any, key: number) => ({
            ...page,
            files: key === 0 ? [file, ...page.files].sort(sortFiles) : page.files,
          })),
        };
      }
      return oldData;
    });
  };

  const removeFileCache = (fileId: string) => {
    updateCache(queryClient, queryKey, (oldData) => {
      if (oldData && oldData.success && oldData.files) {
        return {
          ...oldData,
          files: oldData.files.filter((file: FileType) => file.id !== fileId),
        };
      }
      return oldData;
    });
  };

  const updateFileCacheGlobal = (file: FileType) => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.pages) {
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            ...page,
            files: page.files.map((f: FileType) => (f.id === file.id ? file : f)),
          })),
        };
      }
      return oldData;
    });
  };

  const addFileCacheGlobal = (newFile: FileType) => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.success && oldData.files) {
        return {
          ...oldData,
          files: [newFile, ...oldData.files].sort(sortFiles),
        };
      }
      return oldData;
    });
  };

  const removeFileCacheGlobal = (fileId: string) => {
    const queries = queryClient.getQueryCache().findAll({
      queryKey: ['load-files'],
    });
    queries.forEach((query) => {
      queryClient.setQueryData(query.queryKey, (oldData: any) => {
        if (!oldData || !oldData.pages) return oldData;
        const updatedPages = oldData.pages.map((page: any) => ({
          ...page,
          files: page.files.filter((file: FileType) => file.id !== fileId),
        }));
        return {
          ...oldData,
          pages: updatedPages,
        };
      });
    });
  };

  const addFileByCacheKey = (cacheKey: QueryKey, newFile: FileType) => {
    updateCache(queryClient, cacheKey, (oldData) => {
      if (oldData && oldData.success && oldData.files) {
        return {
          ...oldData,
          files: [newFile, ...oldData.files].sort(sortFiles),
        };
      }
      return oldData;
    });
  };

  const invalidateAllCaches = () => {
    queryClient.invalidateQueries();
  };

  const invalidateCache = () => {
    queryClient.refetchQueries({
      queryKey: queryKey,
    });
  };

  return {
    addFileCache,
    removeFileCache,
    updateFileCacheGlobal,
    addFileCacheGlobal,
    invalidateAllCaches,
    removeFileCacheGlobal,
    addFileByCacheKey,
    invalidateCache,
    getFilesFoldersFromCache,
  };
};

export type CopyFilePayload = {
  file_id: string;
  name: string;
  destination_id: string;
};

export const useCopyFile = () => {
  const sendData = async (payload: CopyFilePayload) => {
    const { destination_id, ...rest } = payload;
    const resp = await API.post(`/resources/${destination_id}/files/copy`, rest, 'archive');
    if (resp.success) {
      return {
        success: true,
        folder: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
