import React from 'react';
import { ReactComponent as HeaderLogo } from 'src/assets/icons/logo.svg';
import { FoldersTree } from 'src/components/FoldersTree';
import { DiskCapacity } from 'src/pages/diskPage/components/DiskDetails/components/DiskCapacity';
import classNames from 'classnames';
import { Uploads } from 'src/components/Uploads';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import './styles.scss';

export const DiskDetails = () => {
  const { disk } = useDiskContext();
  const [isEmptyTree, setIsEmptyTree] = React.useState(true);

  return (
    <div className="disk-details">
      <div className="disk-details__header">
        <div className="disk-details__header-content">
          <div className="disk-details__header-title">{disk.name}</div>
          <div className="disk-details__header-number">
            {String(disk.disk_number).replace(/(\d{4})(?=\d)/g, '$1 ')}
          </div>
        </div>
        <div className="disk-details__header-logo">
          <HeaderLogo />
        </div>
      </div>
      <div className={classNames('disk-details__content', { emptyTree: isEmptyTree })}>
        <FoldersTree diskId={disk.id} onInit={(hasFolders) => setIsEmptyTree(!hasFolders)} />
        <Uploads />
      </div>

      <div className="disk-details__footer">
        <DiskCapacity />
      </div>
    </div>
  );
};
