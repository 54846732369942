import { API } from 'src/utils/API';
import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { objectToQueryParams } from 'src/helpers';
import { FolderType } from 'src/types';
import { sortFolders } from 'src/helpers/sort';
import { updateCache, updateGlobalCache } from 'src/api/ cacheUtils';

export type CreateFolderPayload = {
  folder_name: string;
  parent_id?: string;
};

export type GetFoldersPayload = {
  parent_id?: string;
};

export const useGetFolders = (payload: GetFoldersPayload, isDisabled: boolean = false) => {
  const queryClient = useQueryClient();
  const queryKey: QueryKey = ['load-folders', payload];

  const { parent_id, ...rest } = payload;

  const params = {
    ...rest,
    offset: 0,
    limit: 1000,
  };

  const fetchData = async () => {
    const resp = await API.get(
      `/resources/${parent_id}/folders?${objectToQueryParams(params)}`,
      'archive',
    );
    if (resp.success) {
      return {
        success: true,
        folders: resp.data.items as FolderType[],
      };
    } else {
      return resp;
    }
  };

  const updateFolderCache = (folder: FolderType) => {
    updateCache(queryClient, queryKey, (oldData: any) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: oldData.folders
            .map((f: FolderType) => {
              return f.id === folder.id ? folder : f;
            })
            .sort(sortFolders),
        };
      }
      return oldData;
    });
  };

  const addFolderCache = (folder: FolderType) => {
    updateCache(queryClient, queryKey, (oldData: any) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: [folder, ...oldData.folders].sort(sortFolders),
        };
      }
      return oldData;
    });
  };

  const removeFolderCache = (folderId: string) => {
    updateCache(queryClient, queryKey, (oldData: any) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: oldData.folders.filter((folder: FolderType) => folder.id !== folderId),
        };
      }
      return oldData;
    });
  };

  const updateFolderCacheGlobal = (folder: FolderType) => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: oldData.folders
            .map((f: FolderType) => (f.id === folder.id ? folder : f))
            .sort(sortFolders),
        };
      }
      return oldData;
    });
  };

  const addFolderCacheGlobal = (newFolder: FolderType) => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: [newFolder, ...oldData.folders].sort(sortFolders),
        };
      }
      return oldData;
    });
  };

  const removeFolderCacheGlobal = (folderId: string) => {
    updateGlobalCache(queryClient, (oldData) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: oldData.folders.filter((folder: FolderType) => folder.id !== folderId),
        };
      }
      return oldData;
    });
  };

  const getFolderFromCacheGlobal = (folderId: string): FolderType | undefined => {
    let folder = undefined;
    const queries = queryClient.getQueryCache().getAll();

    for (const query of queries) {
      if (query.queryKey[0] === 'load-folders') {
        const data = query.state.data as { success: boolean; folders: FolderType[] };
        if (data && data.success && data.folders) {
          const temp = data.folders.find((folder: FolderType) => folder.id === folderId);
          if (temp) {
            folder = temp;
          }
        }
      }
    }
    return folder;
  };

  const addFolderByCacheKey = (cacheKey: QueryKey, newFolder: FolderType) => {
    updateCache(queryClient, cacheKey, (oldData: any) => {
      if (oldData && oldData.success && oldData.folders) {
        return {
          ...oldData,
          folders: [newFolder, ...oldData.folders].sort(sortFolders),
        };
      }
      return oldData;
    });
  };

  const { data, ...query } = useQuery({
    queryKey,
    queryFn: fetchData,
    staleTime: 1000 * 60 * 5 * 10,
    enabled: !!parent_id && !isDisabled,
    retry: 0,
  });

  const invalidateCache = () => {
    query.refetch();
  };

  return {
    data,
    ...query,
    invalidateCache,
    updateFolderCache,
    addFolderCache,
    removeFolderCache,
    updateFolderCacheGlobal,
    addFolderCacheGlobal,
    removeFolderCacheGlobal,
    addFolderByCacheKey,
    getFolderFromCacheGlobal,
  };
};

export const useCreateFolder = () => {
  const sendData = async (payload: CreateFolderPayload) => {
    const { parent_id, ...rest } = payload;
    const resp = await API.post(`/resources/${parent_id}/folders`, rest, 'archive');
    if (resp.success) {
      return {
        success: true,
        folder: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};

export const useGetFolderStats = (folderId: string) => {
  const fetchData = async () => {
    const resp = await API.get(`/resources/folder/${folderId}/statistics`, 'archive');
    if (resp.success) {
      return {
        success: true,
        stats: resp.data,
      };
    } else {
      return resp;
    }
  };

  const queryKey: QueryKey = ['load-folder-stats', folderId, folderId];

  const { data, ...query } = useQuery({
    queryKey,
    queryFn: fetchData,
    staleTime: 0,
    retry: 0,
    enabled: !!folderId?.length,
  });

  return {
    data,
    ...query,
  };
};

export type CopyFolderPayload = {
  folder_id: string;
  destination_id: string;
};

export const useCopyFolder = () => {
  const sendData = async (payload: CopyFolderPayload) => {
    const { destination_id, ...rest } = payload;
    const resp = await API.post(`/resources/${destination_id}/folders/copy`, rest, 'archive');
    if (resp.success) {
      return {
        success: true,
        folder: resp.data,
      };
    } else {
      return resp;
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 0,
  });
};
