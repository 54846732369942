import React, { FC, useEffect, useState } from 'react';
import { Header } from 'src/components/Header';
import { DiskLayout } from 'src/pages/diskPage/DiskLayout';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { createTransition } from 'dnd-multi-backend';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useNavigate, useParams } from 'react-router-dom';
import { DiskTabs } from 'src/components/DiskTabs';
import { DiskDetails } from 'src/pages/diskPage/components/DiskDetails';
import { LeftSection } from 'src/components/LeftSection';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { Trash } from 'src/pages/trashPage/Trash';
import { useTrashState } from 'src/pages/trashPage/useTrashState';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { useDiskManage } from 'src/hooks/useDiskManage';
import './styles.scss';

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: createTransition('pointerdown', () => true),
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: createTransition('touchstart', () => true),
    },
  ],
};

export const TrashPage: FC = () => {
  const { createDisk, openDiskInfo } = useDiskManage();
  const isTrash = true;
  const { clearSelectedIds } = useGlobalContext();
  const navigate = useNavigate();
  const { diskId, folderId } = useParams();
  const { setDisk, disk } = useDiskContext();
  const { view } = useDiskContext();
  const [, setIsLoaded] = useState(false);
  const { onDeleteAllClick, onRestoreAllClick } = useTrashState();
  const { disks, isLoading } = useGetDisks();

  useEffect(() => {
    if (isLoading) return;
    const navigateToHome = () => navigate('/disk/');

    const navigateToDisk = (id: string) => navigate(`/disk/${id}`);

    if (disks.length > 0) {
      if (!diskId) {
        setIsLoaded(true);
        navigateToDisk(disks[0].id);
      } else {
        const disk = disks.find((disk) => disk.id === diskId);
        if (disk) {
          setIsLoaded(true);
          setDisk(disk);
        } else {
          navigateToDisk(disks[0].id);
        }
      }
    } else {
      if (diskId) {
        navigateToHome();
      } else {
        setIsLoaded(true);
      }
    }
  }, [disks, isLoading, diskId]);

  useEffect(() => {
    clearSelectedIds();
  }, [diskId, folderId]);

  return (
    <div className="layout-wrapper">
      <DndProvider backend={HTML5Backend} options={HTML5toTouch}>
        <DiskLayout
          leftSidebar={
            <div className="sidebar-columns">
              <LeftSection />
              <DiskDetails />
            </div>
          }
        >
          <div className="dashboard-content">
            <>
              <DiskTabs
                disks={disks}
                onTabClick={(disk) => {
                  setDisk(disk);
                  navigate(`/disk/${disk.id}`);
                }}
                onAddClick={() => {
                  createDisk();
                }}
              />
              <Header
                onClickMenu={() => {
                  openDiskInfo(disk);
                }}
                hideBreadCrumbs={view === 'columns'}
                isTrash={isTrash}
                onRestoreAllClick={onRestoreAllClick}
                onDeleteAllClick={onDeleteAllClick}
              />
              <Trash />
            </>
          </div>
        </DiskLayout>
      </DndProvider>
    </div>
  );
};
