import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export const News = () => {
  const { t } = useTranslation();
  const news = [
    {
      media: <div className="home-news__media"></div>,
      title: 'Раскройте новый потенциал с помощью облачного сервиса UMI',
      description:
        'Мы рады представить обновленные функции облачного сервиса UMI! Теперь вы можете управлять своими данными еще проще и быстрее благодаря улучшенной производительности и новым инструментам анализа. Откройте для себя новые возможности и сделайте свою работу еще более эффективной. Присоединяйтесь к нам и первыми ощутите все преимущества обновлений!',
    },
    {
      media: <div className="home-news__media"></div>,
      title: 'Раскройте новый потенциал с помощью облачного сервиса UMI',
      description:
        'Мы рады представить обновленные функции облачного сервиса UMI! Теперь вы можете управлять своими данными еще проще и быстрее благодаря улучшенной производительности и новым инструментам анализа. Откройте для себя новые возможности и сделайте свою работу еще более эффективной. Присоединяйтесь к нам и первыми ощутите все преимущества обновлений!',
    },
  ];

  return (
    <div className="home-news">
      <div className="landing-container">
        <h2 className="home-news__title">{t('newsTitle')}</h2>
        <div className="home-news__items">
          {news.map((item, index) => (
            <div key={index} className="home-news__item">
              <div className="home-news__item-media">{item.media}</div>
              <div className="home-news__item-title">{item.title}</div>
              <div className="home-news__item-description">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
