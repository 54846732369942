import React, { FC } from 'react';
import { DragPreviewImage } from 'react-dnd';
import classNames from 'classnames';
import { transparentImage } from 'src/pages/diskPage/components/DragLayer/DragLayer';
import { useTranslation } from 'react-i18next';
import { TableItemType } from 'src/components/Table/types';
import { useRowState } from 'src/components/Table/hooks/useRowState';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { useFileState } from 'src/pages/diskPage/hooks/useFileState';
import { useFolderState } from 'src/pages/diskPage/hooks/useFolderState';
import './styles.scss';

type Props = {
  className?: string;
  columns: any;
  index: number;
  key: string;
  style: React.CSSProperties;
  item: any;
  onRowClick?: (item: TableItemType) => void;
  disableDrag?: boolean;
};
export const Row: FC<Props> = ({
  className,
  columns,
  key,
  style,
  item,
  onRowClick,
  disableDrag,
}) => {
  const { t } = useTranslation();
  const { items } = useFileState(item);
  const { items: folderItems } = useFolderState(item);

  const {
    drag,
    preview,
    drop,
    isDragging,
    isOver,
    onRowClickHandler,
    onContextMenu,
    selectedIds,
    isGroupDragging,
  } = useRowState(item, onRowClick, disableDrag);

  const isTrashTable = false;
  return (
    <>
      <DragPreviewImage connect={preview} src={transparentImage} />
      <ContextDropdown
        trigger={
          <div
            key={key}
            className={classNames('list-file list-item table__row select-item', className, {
              'is-dragging': isDragging,
              isOver: isOver,
              selected: selectedIds.includes(item.id),
              dragging: (isGroupDragging && selectedIds.includes(item.id)) || isDragging,
            })}
            style={{
              ...style,
            }}
            ref={(node) =>
              !isTrashTable
                ? item.type === t('folderWithFiles')
                  ? drag(drop(node))
                  : drag(node)
                : null
            }
            onContextMenu={onContextMenu}
            onClick={onRowClickHandler}
            id={`${item.id}`}
          >
            {columns}
          </div>
        }
        items={item.innerType !== 'folder' ? items : folderItems}
        id={`${item.id}-name`}
        as={'div'}
        className="list-view__table"
      />
    </>
  );
};
