import React, { FC } from 'react';
import { FolderType } from 'src/types';
import { FolderIcon, FolderFavoriteIcon } from 'src/components/Icons';
import { ContextDropdown } from 'src/components/Dropdown/context';
import { ActionButton } from 'src/components/Forms/ActionButton';
import classNames from 'classnames';
import { useFolderState } from 'src/pages/diskPage/hooks/useFolderState';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { transparentImage } from 'src/pages/diskPage/components/DragLayer/DragLayer';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { useFolderDrop } from 'src/pages/diskPage/hooks/useFolderDrop';
import './styles.scss';

type Props = {
  folder: FolderType;
  onClick: (folder: FolderType) => void;
};

export const FolderComponent: FC<Props> = ({ folder, onClick }) => {
  const {
    updateState,
    isSelected,
    selectedIdsRef,
    filesRef,
    foldersRef,
    selectedIds,
    toggleSection,
    isGroupDragging,
  } = useDragState();

  const { items } = useFolderState(folder);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'folder',
    item: () => {
      updateState({ isDragging: true });
      return {
        folders: isSelected(selectedIdsRef.current, folder.id)
          ? foldersRef.current
          : [...foldersRef.current, folder],
        files: filesRef.current,
      };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: () => updateState({ isDragging: false }),
  }));

  const { drop, isOver, canDrop } = useFolderDrop(folder);

  const onFolderClick = () => {
    if (selectedIds.length > 0) {
      toggleSection(folder.id);
      return;
    }
    onClick(folder);
  };

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.ctrlKey || event.metaKey) {
      toggleSection(folder.id);
    }
  };

  return (
    <ContextDropdown
      trigger={
        <>
          <DragPreviewImage connect={preview} src={transparentImage} />
          <div
            ref={(node) => drag(drop(node))}
            className={classNames('single-folder', 'drop-area select-item', {
              favorite: folder.is_favorite,
              over: isOver,
              wow: canDrop,
              selected: selectedIds.includes(folder.id),
              groupDragging: (isGroupDragging && selectedIds.includes(folder.id)) || isDragging,
            })}
            onClick={onFolderClick}
            onContextMenu={onContextMenu}
            id={folder.id}
          >
            <div className="single-folder__media">
              {folder.is_favorite ? (
                <FolderFavoriteIcon className="folder-icon" />
              ) : (
                <FolderIcon className="folder-icon" />
              )}
            </div>
            <div className="single-folder__name" title={folder.name}>
              {folder.name}
            </div>
            <div className="single-folder__action">
              <ActionButton items={items} className="disk__abtn" id={folder.id} />
            </div>
          </div>
        </>
      }
      items={items}
      id={folder.id}
    />
  );
};
