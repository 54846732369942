import CryptoJS from 'crypto-js';
import LocalStorage from 'src/utils/Storage';

export const objectToQueryParams = (obj: Record<string, any>): string => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item !== undefined) {
            params.append(key, item.toString());
          }
        });
      } else {
        params.append(key, value.toString());
      }
    }
  });
  return params.toString();
};

export function convertBytes(bytes: number): { size: number; unit: string } {
  const sizeInPoint = 1000;

  if (bytes >= sizeInPoint * sizeInPoint * sizeInPoint) {
    return { size: Math.round(bytes / (sizeInPoint * sizeInPoint * sizeInPoint)), unit: 'GB' };
  } else if (bytes >= sizeInPoint * sizeInPoint) {
    return { size: Math.round(bytes / (sizeInPoint * sizeInPoint)), unit: 'MB' };
  } else if (bytes >= sizeInPoint) {
    return { size: Math.round(bytes / sizeInPoint), unit: 'KB' };
  } else {
    return { size: bytes, unit: 'Bytes' };
  }
}

export const convertGbToTb = (gb: number): number => {
  if (gb < 1000) return gb;
  const tb = gb / 1000;
  return parseFloat(tb.toFixed(2));
};

export function getConvertedSize(b: number): string {
  const bytes = Math.abs(b);
  const sizeInPoint = 1000;

  const formatSize = (value: number): string => {
    return value % 1 === 0 ? value.toString() : value.toFixed(2).replace(/\.?0+$/, '');
  };

  if (bytes >= sizeInPoint * sizeInPoint * sizeInPoint * sizeInPoint) {
    return `${formatSize(bytes / (sizeInPoint * sizeInPoint * sizeInPoint * sizeInPoint))} TB`;
  } else if (bytes >= sizeInPoint * sizeInPoint * sizeInPoint) {
    return `${formatSize(bytes / (sizeInPoint * sizeInPoint * sizeInPoint))} GB`;
  } else if (bytes >= sizeInPoint * sizeInPoint) {
    return `${formatSize(bytes / (sizeInPoint * sizeInPoint))} MB`;
  } else if (bytes >= sizeInPoint) {
    return `${formatSize(bytes / sizeInPoint)} KB`;
  } else {
    return `${bytes} Bytes`;
  }
}

export const getBrowserFingerprint = () => {
  const fingerprint = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    screenResolution: [window.screen.width, window.screen.height],
    colorDepth: window.screen.colorDepth,
    timezoneOffset: new Date().getTimezoneOffset(),
    localStorage: !!window.localStorage,
    sessionStorage: !!window.sessionStorage,
    indexedDB: !!window.indexedDB,
  };

  const fingerprintString = JSON.stringify(fingerprint);
  return CryptoJS.MD5(fingerprintString).toString(CryptoJS.enc.Hex);
};

export const getFileExtension = (fileName: string): string => {
  return fileName.split('.').pop() || '';
};

export const convertBinaryToBase64 = (binaryData: Uint8Array) => {
  return new Promise((resolve, reject) => {
    const toHexString = (bytes: Uint8Array) => {
      return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
    };
    const fileSignature = toHexString(binaryData.slice(0, 4));

    let mimeType = '';
    switch (fileSignature.toUpperCase()) {
      case '89504E47':
        mimeType = 'image/png';
        break;
      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFE1':
        mimeType = 'image/jpeg';
        break;
      default:
        return reject(new Error('Unsupported file type'));
    }

    const blob = new Blob([binaryData], { type: mimeType });
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};

export const base64ToBlob = (base64: string) => {
  const getMimeTypeFromBase64 = (base64: string) => {
    if (base64.startsWith('data:')) {
      return base64.substring(5, base64.indexOf(';base64'));
    }
    return '';
  };
  const base64String = base64.split(',')[1];
  const binaryString = atob(base64String);
  const length = binaryString.length;
  const byteArray = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return new Blob([byteArray], { type: getMimeTypeFromBase64(base64) });
};

export function convertCentsToDollars(cents: number): string {
  const dollars = cents / 100;
  return dollars.toFixed(2);
}
