import React, { FC, useEffect, useState } from 'react';
import Selecto from 'react-selecto';
import { DndProvider } from 'react-dnd';
import { Header } from 'src/components/Header';
import { DiskLayout } from 'src/pages/diskPage/DiskLayout';
import { ColumnsView, IconsView, ListView } from 'src/pages/diskPage/Views';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { createTransition } from 'dnd-multi-backend';
import { useDiskContext } from 'src/context/DiskContext/useDiskContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from 'src/components/Skeletons';
import { DiskTabs } from 'src/components/DiskTabs';
import { DiskDetails } from 'src/pages/diskPage/components/DiskDetails';
import { LeftSection } from 'src/components/LeftSection';
import { DragLayer } from './components/DragLayer/DragLayer';
import { useGlobalContext } from 'src/context/GlobalContext/useGlobalContext';
import { Trash } from 'src/pages/trashPage/Trash';
import { useGetDisks } from 'src/hooks/useGetDisks';
import { useAppContext } from 'src/context/AppContext/useAppcontext';
import { FailedModal } from 'src/pages/diskPage/NotificationsModals/FailedModal';
import { SuccessModal } from 'src/pages/diskPage/NotificationsModals/SuccessModal';
import { useDiskManage } from 'src/hooks/useDiskManage';
import { HashtagsList } from 'src/pages/diskPage/components';
import LocalStorage from 'src/utils/Storage';
import { useSubscription } from 'src/components/Subscription/useSubscription';
import { LockedDisk } from 'src/pages/diskPage/components/LockedDisk';
import './styles.scss';

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: createTransition('pointerdown', () => true),
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: createTransition('touchstart', () => true),
    },
  ],
};

type Props = {
  isTrash?: boolean;
};

export const DiskPage: FC<Props> = ({ isTrash }) => {
  const { openModal } = useAppContext();
  const { addSection, clearSelectedIds } = useGlobalContext();
  const navigate = useNavigate();
  const { diskId, folderId } = useParams();
  const { setDisk, disk, view, showHashTags } = useDiskContext();

  const { createDisk, openDiskInfo } = useDiskManage();
  const { openSubscriptionArea } = useSubscription();

  const [isLoaded, setIsLoaded] = useState(false);

  const { disks, isLoading } = useGetDisks();

  const clearUrl = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState(null, '', newUrl);
  };

  useEffect(() => {
    if (isLoading) return;
    const navigateToHome = () => {
      const params = new URLSearchParams(window.location.search);
      const subscription = params.get('subscription');
      const url = subscription ? `/disk/?subscription=${subscription}` : '/disk/';

      return navigate(url);
    };

    const navigateToDisk = (id: string) => {
      const params = new URLSearchParams(window.location.search);
      const subscription = params.get('subscription');
      const url = subscription ? `/disk/${id}?subscription=${subscription}` : `/disk/${id}`;
      return navigate(url);
    };

    if (disks.length > 0) {
      if (!diskId) {
        setIsLoaded(true);
        navigateToDisk(disks[0].id);
      } else {
        const disk = disks.find((disk) => disk.id === diskId);
        if (disk) {
          setIsLoaded(true);
          setDisk(disk);
        } else {
          navigateToDisk(disks[0].id);
        }
      }
    } else {
      if (diskId) {
        navigateToHome();
      } else {
        setIsLoaded(true);
      }
    }

    const params = new URLSearchParams(window.location.search);
    const subscription = params.get('subscription');
    if (subscription === 'failed') {
      openModal({
        content: (
          <>
            <FailedModal
              onCLose={() => {
                openModal({ open: false });
                clearUrl();
              }}
              onRepeat={() => {
                openModal({ open: false });
                clearUrl();
              }}
            />
          </>
        ),
        title: '',
        className: 'notification-modal',
        width: '450px',
        hideHeader: true,
      });

      return;
    }
    if (subscription === 'success') {
      openModal({
        content: (
          <>
            <SuccessModal
              onCLose={() => {
                openModal({ open: false });
                clearUrl();
              }}
            />
          </>
        ),
        title: '',
        className: 'notification-modal',
        width: '450px',
        hideHeader: true,
      });

      return;
    }
  }, [disks, isLoading, diskId]);

  useEffect(() => {
    clearSelectedIds();
  }, [diskId, folderId]);

  useEffect(() => {
    const data = LocalStorage.get('storageParameters');
    const hasSubscription = LocalStorage.get('businessSubscription');
    if (data) {
      const { id, ...rest } = data;
      createDisk(rest);
      LocalStorage.remove('storageParameters');
    }
    if (hasSubscription) {
      openSubscriptionArea();
      LocalStorage.remove('businessSubscription');
    }
  }, []);

  const views = {
    icons: <IconsView />,
    list: <ListView />,
    columns: <ColumnsView />,
  };

  return (
    <div className="layout-wrapper">
      <DndProvider backend={HTML5Backend} options={HTML5toTouch}>
        <DiskLayout
          leftSidebar={
            <div className="sidebar-columns">
              <LeftSection />
              <DiskDetails />
            </div>
          }
        >
          <div className="dashboard-content">
            <>
              <DiskTabs
                disks={disks}
                onTabClick={(disk) => {
                  setDisk(disk);
                  navigate(`/disk/${disk.id}`);
                }}
                onAddClick={() => {
                  createDisk();
                }}
              ></DiskTabs>

              {disk.is_blocked && (
                <>
                  <LockedDisk />
                </>
              )}
              {!disk.is_blocked && (
                <>
                  <Header
                    onClickMenu={() => {
                      openDiskInfo(disk);
                    }}
                    hideBreadCrumbs={view === 'columns'}
                    isTrash={isTrash}
                  />
                  {!isTrash && !showHashTags && (
                    <>{isLoaded ? views[view] : <Skeleton type="disk" />}</>
                  )}
                  {isTrash && <Trash />}
                  {showHashTags && <HashtagsList />}
                </>
              )}
            </>

            {!disk.is_blocked && (
              <Selecto
                selectableTargets={['.select-item']}
                hitRate={5}
                selectByClick={false}
                selectFromInside={false}
                toggleContinueSelect={['shift']}
                onSelect={(e) => {
                  const selected = e.selected.map((el) => ({
                    id: el.getAttribute('id') || '',
                    name: el.textContent || '',
                  }));
                  if (selected.length !== 0) {
                    /* clearSelectedIds();*/
                  }

                  selected.forEach((el) => {
                    addSection(el.id);
                  });
                }}
                onDragStart={(e) => {
                  const target = e.inputEvent.target as HTMLElement;
                  if (target.closest('.select-item')) {
                    e.preventDrag();
                  }
                }}
                onSelectStart={() => {
                  //clearSelectedIds();
                }}
              />
            )}
          </div>
        </DiskLayout>
        <DragLayer />
      </DndProvider>
    </div>
  );
};
