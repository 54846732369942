import { useEffect, useRef } from 'react';

export const useOutside = (closeFunc: () => void) => {
  const panelRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (
      panelRef.current &&
      !panelRef.current.contains(target) &&
      !document.querySelector('.react-resizable')?.contains(target)
    ) {
      closeFunc();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    panelRef,
  };
};
