import { useDrop } from 'react-dnd';
import { FileType, FolderType } from 'src/types';
import { useMoveObject } from 'src/api/objects';
import { useCallback } from 'react';
import { useDragState } from 'src/pages/diskPage/hooks/useDragState';
import { useDiskViewState } from 'src/pages/diskPage/hooks/useDiskViewState';
import { FilesPayload, useFileCache } from 'src/api/files';
import { DEFAULT_FILES_PAYLOAD } from 'src/config/constants';
import { useGetFilteredFolders } from 'src/hooks/useGetFilteredFolders';
import { useParams } from 'react-router-dom';

export const useFolderDrop = (folder: FolderType) => {
  const { diskId } = useParams();
  const parent_id = folder?.id || diskId || '';

  const { selectedIds, clearSelectedIds } = useDragState();
  const { invalidateCache: refetchFolders } = useGetFilteredFolders({ parent_id }, true);

  const filesPayload: FilesPayload = {
    ...DEFAULT_FILES_PAYLOAD,
    parent_id,
  };
  const { invalidateCache: refetchFiles } = useFileCache(filesPayload);

  const invalidateCache = () => {
    refetchFiles();
    refetchFolders();
  };

  const { removeFileCache, removeFolderCache } = useDiskViewState();
  const { mutateAsync: moveFile } = useMoveObject();

  const onDrop = useCallback(
    (data: { files: FileType[]; folders: FolderType[] }) => {
      const ids = data.files
        .filter((f) => f.parent_id !== parent_id)
        .map((f) => f.id)
        .concat(data.folders.filter((f) => f.parent_id !== parent_id).map((f) => f.id))
        .filter((id) => id !== parent_id);

      onDropHandler(ids);
    },
    [folder, selectedIds, clearSelectedIds],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ['file', 'folder'],
      drop: onDrop,
      canDrop: (item, monitor) => {
        return monitor.isOver({ shallow: true });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    }),
    [folder, parent_id, selectedIds],
  );

  const onDropHandler = async (ids: string[]) => {
    if (ids.length === 0) return;
    const response = await moveFile({
      destination_id: parent_id,
      item_ids: ids,
    });
    if (response.success) {
      ids.forEach((id) => {
        removeFileCache(id);
        removeFolderCache(id);
      });
      clearSelectedIds();
    }
    invalidateCache();
  };

  return {
    drop,
    isOver,
    canDrop,
  };
};
