import React, { useState } from 'react';
import moment from 'moment';
import { ArrowTopIcon } from 'src/components/Icons';
import classNames from 'classnames';
import { AnimatedClip } from 'src/components/AnimatedClip';
import { ReactComponent as NotificationIcon } from '../../assets/notification-icon.svg';
import { useGetNotifications } from 'src/api/notifications';
import { NotificationType } from 'src/types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import 'moment/locale/ru';
import 'moment/locale/fr';
import 'moment/locale/uk';
import './styles.scss';

const groupNotificationsByDate = (notifications: NotificationType[]) => {
  return notifications.reduce((groups: { [key: string]: NotificationType[] }, notification) => {
    const date = moment.utc(notification.created_at).format('YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(notification);
    return groups;
  }, {});
};

export const Notifications: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true); // Collapsed by default
  moment.locale(i18n.language);
  const { data } = useGetNotifications({ disabled: false });
  const notificationsItems: NotificationType[] = get(data, 'notifications', []);
  const groupedNotifications = groupNotificationsByDate(notificationsItems);

  const flatNotifications = Object.keys(groupedNotifications).reduce(
    (acc: { date: string; notifications: NotificationType[] }[], date: string) => {
      acc.push({
        date,
        notifications: groupedNotifications[date],
      });
      return acc;
    },
    [],
  );

  const getVisibleNotifications = () => {
    if (isCollapsed) {
      let count = 0;
      const visibleNotifications = flatNotifications.map((group) => {
        const visibleItems = group.notifications.slice(0, Math.max(0, 4 - count));
        count += visibleItems.length;
        return {
          ...group,
          notifications: visibleItems,
        };
      });
      return visibleNotifications.filter((group) => group.notifications.length > 0);
    }
    return flatNotifications;
  };

  return (
    <div className="notifications-list">
      <div className="notifications-list__title">
        <div className="notifications-list__title--content">
          <NotificationIcon className="notifications-list__title-icon" />
          <span>{t('notifications')}</span>
        </div>

        {notificationsItems.length > 0 && (
          <div className={classNames('notifications-list__toggle', { reverted: isCollapsed })}>
            <ArrowTopIcon onClick={() => setIsCollapsed(!isCollapsed)} />
          </div>
        )}
      </div>

      <div className="notifications-list__content">
        {getVisibleNotifications().map((group) => (
          <div key={group.date}>
            <div className="notifications-list__date">
              {moment.utc(group.date).calendar(null, {
                sameDay: `[${t('today')}]`,
                lastDay: `[${t('yesterday')}]`,
                lastWeek: 'dddd',
                sameElse: 'DD MMMM YYYY',
              })}
            </div>
            {group.notifications.map((notification: NotificationType) => (
              <div key={notification.id} className="notifications-list__row">
                <div className="notifications-list__item">
                  <div className="notifications-list__icon">
                    <AnimatedClip type="convenience" />
                  </div>
                  <div className="notifications-list__item-content">
                    <div className="notifications-list__item-title">{notification.title}</div>
                    <div className="notifications-list__item-message">{notification.subtitle}</div>
                  </div>
                  <div className="notifications-list__item-time">
                    {!notification.is_read && <div className="notifications-list__indicator"></div>}
                    {moment.utc(notification.created_at).format('HH:mm')}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}

        {!notificationsItems.length && (
          <div className="notifications-list__empty">{t('noNotifications')}</div>
        )}
      </div>
    </div>
  );
};
