import React, { FC, useState } from 'react';
import ReactSlider from 'react-slider';
import { ReactComponent as TooltipTop } from './assets/tooltip-top.svg';
import './styles.scss';
import { convertGbToTb } from 'src/pages/landings/Cloud/components/PriceCalculator';

type Props = {
  onChange: (value: number) => void;
};

export const Slider: FC<Props> = ({ onChange }) => {
  const marks = [25, 10000]; // Start with 25 GB, end with 10 TB (10000 GB)
  const minValue = marks[0];
  const maxValue = marks[marks.length - 1];
  const [currentValue, setCurrentValue] = useState<number | string>(minValue);

  const handleChange = (value: number) => {
    setCurrentValue(value);
    onChange(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      setCurrentValue('');
    } else {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue)) {
        setCurrentValue(numericValue);
      }
    }
  };

  const handleInputBlur = () => {
    if (currentValue === '') {
      setCurrentValue(minValue);
      onChange(minValue);
    } else if (typeof currentValue === 'number') {
      let value = currentValue;
      if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }
      setCurrentValue(value);
      onChange(value);
    }
  };

  return (
    <div className="storage-slider">
      <div className="storage-slider__value">
        <input
          value={currentValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min={minValue}
          max={maxValue}
        />
        <span>GB</span>
      </div>
      <div className="slider-container">
        <div className="slider-labels-top">
          <span className="label-top" style={{ left: '0%' }}>
            25 GB
          </span>
          <span className="label-top" style={{ left: '100%' }}>
            10 TB
          </span>
        </div>

        <ReactSlider
          className="custom-slider"
          min={minValue}
          max={maxValue}
          step={1}
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          value={typeof currentValue === 'number' ? currentValue : minValue}
          onChange={handleChange}
          renderThumb={(props, state) => (
            <div {...props}>
              <div className="tooltip">
                <TooltipTop className="tooltip-marker" />
                {state.valueNow >= 1000
                  ? `${convertGbToTb(state.valueNow)} TB`
                  : `${state.valueNow} GB`}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};
