import { UserType } from 'src/types/users';
import { DiskType } from 'src/types';

export const defaultUserProfile: UserType = {
  accepted_privacy_policy: true,
  accepted_tos: true,
  avatarData: '',
  avatar_url: '',
  department: '',
  email: null,
  full_name: '',
  id: '',
  is_email_confirmed: null,
  last_online_at: '',
  phone_number: '',
  position: '',
  user_name: '',
};

export const emptyDisk: DiskType = {
  id: '',
  name: '',
  filled_volume: 0,
  total_volume: 0,
  statistics: null,
  full_statistics: null,
  is_favorite: false,
  avatar_key: null,
  created_at: '',
  last_modified_at: '',
  color: 'blue',
  disk_number: 0,
};

export const notification = {
  ID: 'e0804ca7-749b-4832-923f-08c55908ec51',
  DeviceToken: 'sadwdas',
  Message: {
    body: 'The link to your file [C] was successfully sent to [!%(*string=<nil>].',
    title: 'Access Granted',
  },
  CreatedAt: '2024-09-10T10:59:08.167001Z',
  IsRead: false,
};

export const mockFiles = [
  {
    id: '8e38902a-bcd6-4b49-902f-80dee32d55f5',
    name: '1.png',
    extension: 'png',
    type: 'IMAGE',
    size: 313560,
    is_favorite: false,
    thumbnails: {
      '500x500': {
        id: '27d68da6-2567-4622-8989-883394785584',
        key: 'ab22b188-3fde-4735-805b-113085a281d8',
        height: 500,
        length: 500,
      },
    },
    created_at: '2024-09-19T13:24:23.217984Z',
    last_modified_at: '2024-09-19T13:24:23.217984Z',
  },
  {
    id: '24690507-b47c-4332-a779-c79c1f9c90e3',
    name: '3.png',
    extension: 'png',
    type: 'IMAGE',
    size: 157192,
    is_favorite: false,
    thumbnails: {
      '500x500': {
        id: '4452f214-950a-40f8-ae9c-4844bf55efd8',
        key: 'f42db811-82b3-4fc6-a6e6-a42fe246a02c',
        height: 500,
        length: 500,
      },
    },
    created_at: '2024-09-19T13:24:23.217902Z',
    last_modified_at: '2024-09-19T13:24:23.217902Z',
  },
  {
    id: '381b3b0f-e21d-45ac-9132-e886849caef8',
    name: '4.png',
    extension: 'png',
    type: 'IMAGE',
    size: 179767,
    is_favorite: false,
    thumbnails: {
      '500x500': {
        id: '0e1b9f00-fb46-4bdb-8715-a2bd79d96c81',
        key: '50df6854-d8c6-4fed-809c-73fe19386674',
        height: 500,
        length: 500,
      },
    },
    created_at: '2024-09-19T13:24:23.217891Z',
    last_modified_at: '2024-09-19T13:24:23.217891Z',
  },
];
