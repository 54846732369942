import { OfferType } from 'src/pages/subscriptions/types';

export const getOffersList = (t: (str: string) => string): OfferType[] => [
  {
    id: 'offer_1',
    storage: t('serverAWS'),
    name: t('standard'),
    color: 'greenGradient',
    capacity: 2000000000000,
    price: '2$',
    description: t('generalPurposeStandard'),
    parameters: 'standard',
  },
  {
    id: 'offer_2',
    storage: t('serverAWS'),
    name: t('highPerformanceShort'),
    color: 'redGradient',
    capacity: 2000000000000,
    price: '2$',
    description: t('expressOneZone'),
    parameters: 'high_performance',
  },
  {
    id: 'offer_3',
    storage: t('serverAWS'),
    name: t('infrequentAccess'),
    color: 'blue',
    capacity: 2000000000000,
    price: '2$',
    description: t('standardIA'),
    parameters: 'infrequent_access',
  },
  {
    id: 'offer_4',
    storage: t('serverAWS'),
    name: t('archiving'),
    color: 'yellowGradient',
    capacity: 2000000000000,
    price: '2$',
    description: t('glacierArchiving'),
    parameters: 'archiving',
  },
];
