import React, { FC } from 'react';
import { getFileStyles } from 'src/pages/diskPage/components/DragLayer/helpers';
import { FileMedia } from 'src/components/FileMedia';
import { FileFavoriteIcon } from 'src/components/Icons';
import { FileType } from 'src/types';
import './styles.scss';

type Props = {
  file: FileType;
  position: { x: number; y: number };
  i: number;
  totalLength: number;
};

export const IconFile: FC<Props> = ({ file, position, i, totalLength }) => {
  return (
    <div className="file iconFile" style={getFileStyles(position, i, totalLength)}>
      <FileMedia
        fileId={file.thumbnails ? file.thumbnails['500x500'].key : null}
        extension={file.extension}
        className={'file__media'}
      >
        {file.is_favorite && <FileFavoriteIcon className="file__favorite" />}
      </FileMedia>
      <div className="file__details">
        <div style={{ width: 'calc(100% - 20px)' }}>
          <div className="file__name" title={file.name}>
            {file.name}
          </div>
        </div>
      </div>
    </div>
  );
};
