import LocalStorage from 'src/utils/Storage';
import { API } from 'src/utils/API';
import { useMutation } from '@tanstack/react-query';
import { LoginState } from 'src/pages/auth/login/types';
import { get } from 'lodash';
import { getBrowserFingerprint } from 'src/helpers';

export const useLogin = () => {
  const sendData = async (payload: LoginState) => {
    const resp = await API.post('/signin', payload);

    if (resp.success) {
      return {
        success: true,
        session: resp.data.session,
      };
    }
    return resp;
  };
  return useMutation({
    mutationFn: sendData,
    retry: 1,
  });
};

export const useOtpCode = () => {
  const sendData = async (payload: LoginState) => {
    const resp = await API.post('/respond', payload);
    if (resp.success) {
      LocalStorage.set('session', resp.data);
      return {
        success: true,
        user_id: resp.data.user_id,
      };
    } else {
      const session = get(resp, 'data.metadata.session', null);
      return {
        success: false,
        error: resp.error,
        session,
      };
    }
  };
  return useMutation({
    mutationFn: sendData,
    retry: 1,
  });
};

export const useSendIdentity = () => {
  const sendData = async (id: string) => {
    const DEVICE_ID = getBrowserFingerprint();
    const resp = await API.post('/firebase/oauth', {
      id_token: id,
      device_id: DEVICE_ID,
      device_type: 'web',
      device_name: 'web',
    });

    if (resp.success) {
      LocalStorage.set('session', resp.data);
      return {
        success: true,
        profile: resp.data,
      };
    }
    return resp;
  };
  return useMutation({
    mutationFn: sendData,
    retry: 1,
  });
};
