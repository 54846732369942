import React, { FC, useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { ReactComponent as TooltipTop } from './assets/tooltip-top.svg';
import './styles.scss';
import { convertGbToTb } from 'src/pages/landings/Cloud/components/PriceCalculator';
import { getConvertedSize } from 'src/helpers';

type Props = {
  onChange: (value: number) => void;
  value?: number;
  min: number;
  max: number;
};

export const Slider: FC<Props> = ({ onChange, value, min, max }) => {
  const marks = [min, max]; // Start with 25 GB, end with 10 TB (10000 GB)
  const minValue = marks[0];
  const maxValue = marks[marks.length - 1];
  const initialCapacity = value ? value : minValue;
  const [currentValue, setCurrentValue] = useState<number | string>(initialCapacity);

  useEffect(() => {
    setCurrentValue(value ? value : minValue);
  }, [value]);
  const handleChange = (value: number) => {
    setCurrentValue(value);
    onChange(value);
  };

  return (
    <div className="storage-slider form-slider">
      <div className="slider-container">
        <div className="slider-labels-top">
          <span className="label-top" style={{ left: '0%' }}>
            {getConvertedSize(min)}
          </span>
          <span className="label-top" style={{ left: '100%' }}>
            {getConvertedSize(max)}
          </span>
        </div>

        <ReactSlider
          className="custom-slider"
          min={minValue}
          max={maxValue}
          step={1}
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          value={typeof currentValue === 'number' ? currentValue : minValue}
          onChange={handleChange}
          renderThumb={(props, state) => (
            <div {...props}>
              <div className="tooltip">
                <TooltipTop className="tooltip-marker" />
                {getConvertedSize(state.valueNow)}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};
