import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from './assets/plus-icon.svg';
import { ReactComponent as CardsIcon } from './assets/cards-icon.svg';
import { ReactComponent as CloudIcon } from './assets/cloud-icon.svg';
import { ReactComponent as NotificationIcon } from './assets/notification-icon.svg';
import { ReactComponent as WalletIcon } from './assets/wallet-icon.svg';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg';
import { AnimatedClip } from 'src/components/AnimatedClip';
import { LeftSlide } from 'src/components/LeftSection/components/LeftSlide';
import { Settings } from 'src/components/LeftSection/components/Settings';
import classNames from 'classnames';
import { UserAvatar } from 'src/components/LeftSection/components/UserAvatar';
import { SupportDevelopers } from 'src/components/LeftSection/components/SupportDevelopers';
import { UserHeader } from 'src/components/LeftSection/components/UserHeader';
import { useOutside } from 'src/hooks/useOutside';
import { SideTabs } from 'src/components/LeftSection/components/SideTabs';
import { PaymentBlock } from 'src/components/LeftSection/components/PaymentBlock';
import { Notifications } from 'src/components/LeftSection/components/Notifications';
import { Operations } from 'src/components/LeftSection/components/Operations';
import { useNavigate } from 'react-router-dom';
import { useDiskManage } from 'src/hooks/useDiskManage';
import './styles.scss';

type Props = {
  onChange?: (value: string) => void;
  animationTime?: number;
};

export const LeftSection: FC<Props> = ({ onChange, animationTime = 300 }) => {
  const { createDisk } = useDiskManage();
  const navigate = useNavigate();

  const [sectionState, setSectionState] = useState({
    showSlide: false,
    showSlideContent: false,
    isOpened: false,
    showLeftIcons: true,
    showProfile: false,
    showPayment: false,
    showBack: false,
  });

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);

  const { panelRef } = useOutside(() => {
    setOpen(false);
  });

  const openPanel = () => {
    setSectionState({
      ...sectionState,
      showSlide: true,
      isOpened: true,
      showLeftIcons: false,

      showProfile: false,
      showBack: false,
    });
    onChange && onChange('open');
    setTimeout(() => {
      setSectionState((state) => ({
        ...state,
        showSlideContent: true,
      }));
    }, animationTime);
    clearTimeout(timer.current as NodeJS.Timeout);
  };

  const closePanel = () => {
    setSectionState((state) => ({
      ...state,
      showSlide: false,
      showSlideContent: false,
      showPayment: false,
    }));

    timer.current = setTimeout(() => {
      setSectionState((state) => ({
        ...state,
        showLeftIcons: true,
        isOpened: false,
      }));
      onChange && onChange('close');
    }, animationTime);
  };

  useEffect(() => {
    if (!open) {
      closePanel();
    } else {
      openPanel();
    }
  }, [open]);

  const openProfile = () => {
    clearTimeout(timer.current as NodeJS.Timeout);
    setSectionState((state) => ({
      ...state,
      showProfile: true,
      showBack: true,
      isOpened: true,
    }));
  };

  const closeProfile = () => {
    setSectionState((state) => ({
      ...state,
      showProfile: false,
      showBack: false,
    }));

    timer.current = setTimeout(() => {
      setSectionState((state) => ({
        ...state,
        isOpened: false,
      }));
    }, animationTime);
  };

  const locked = useRef(false);
  const onBottomClick = () => {
    if (locked.current) return false;
    locked.current = true;
    setOpen(!open);
    setTimeout(() => {
      locked.current = false;
    }, animationTime);
  };

  const toggleProfile = () => {
    if (sectionState.showProfile) {
      closeProfile();
    } else {
      openProfile();
    }
  };

  const togglePaymentBlock = () => {
    setSectionState({
      ...sectionState,
      showPayment: !sectionState.showPayment,
    });
  };

  useEffect(() => {
    onChange && onChange(sectionState.showProfile ? 'open' : 'close');
  }, [sectionState.showProfile]);

  return (
    <div
      className={classNames('left-section', {
        open: sectionState.showSlide,
        opened: sectionState.isOpened,
        noAnimation: animationTime === 0,
      })}
      ref={panelRef}
    >
      <div className="left-section__content">
        <div className="left-section__dynamic-content">
          {sectionState.showLeftIcons && (
            <>
              <div
                className={classNames('left-section__media', { hideBorder: sectionState.showBack })}
                onClick={toggleProfile}
              >
                {sectionState.showBack ? (
                  <div className="left-section__top-back">
                    <ArrowRight />
                  </div>
                ) : (
                  <UserAvatar />
                )}
              </div>
              <div className="left-section__btns">
                <div
                  className="left-section__btn skip-outside"
                  onClick={() => {
                    createDisk();
                  }}
                >
                  <PlusIcon />
                </div>
                <div
                  className="left-section__btn"
                  onClick={() => {
                    setOpen(true);
                    togglePaymentBlock();
                  }}
                >
                  <CardsIcon />
                </div>
                <div
                  className="left-section__btn"
                  onClick={() => {
                    navigate('/subscriptions');
                  }}
                >
                  <CloudIcon />
                </div>
                <div
                  className="left-section__btn"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <NotificationIcon />
                </div>
                <div
                  className="left-section__btn"
                  onClick={() => {
                    navigate('/subscriptions');
                  }}
                >
                  <WalletIcon />
                </div>
              </div>
            </>
          )}
          {sectionState.showSlideContent && (
            <>
              <UserHeader />
              <SideTabs
                isPaymentBlockVisible={sectionState.showPayment}
                onMyBalanceClick={togglePaymentBlock}
                onMySubscriptionsClick={() => {
                  navigate('/subscriptions');
                }}
                onNewDiskClick={() => {
                  createDisk();
                }}
              />
              <div className="left-section__section">
                {sectionState.showPayment && <PaymentBlock />}
                <Notifications />
                <Operations />
              </div>
            </>
          )}
        </div>

        <div className="left-section__item">
          {sectionState.showLeftIcons && <AnimatedClip type="cool" />}
          {sectionState.showSlideContent && <SupportDevelopers />}
          <div className="left-section__btn bottom" onClick={onBottomClick}>
            <ArrowRight />
          </div>
        </div>
      </div>
      <LeftSlide open={sectionState.showProfile} onClose={closeProfile} onOpen={openProfile}>
        <Settings />
      </LeftSlide>
    </div>
  );
};
