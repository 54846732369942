import React, { useEffect, useState } from 'react';
import { CloseIcon } from 'src/components/Icons';
import { ReactComponent as Logo } from 'src/pages/landings/assets/UMI-search-logo-blue.svg';
import { useTranslation } from 'react-i18next';
import { CreatingDisk } from 'src/pages/subscriptions/components/CreatingDisk';
import { SubscriptionDisk } from 'src/pages/subscriptions/components/SubscriptionDisk';
import { getOffersList } from 'src/pages/subscriptions/offers';
import { OfferItem } from 'src/pages/subscriptions/components/OfferItem';
import { BusinessItem } from 'src/pages/subscriptions/components/BussinesItem';
import { emptyDisk } from 'src/dataMocks';
import { DiskType } from 'src/types';
import './styles.scss';
import { OfferType } from 'src/pages/subscriptions/types';
import LocalStorage from 'src/utils/Storage';

interface FullModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAction: () => void;
}

export const FullModal: React.FC<FullModalProps> = ({ isOpen, onClose, onAction }) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(isOpen);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      setIsAnimating(true);
      setTimeout(() => {
        setIsVisible(false);
        setIsAnimating(false);
      }, 300);
    }
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  const handleClose = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClose();
      setIsAnimating(false);
    }, 300);
  };

  const onSignUp = () => {
    onAction();
  };

  const onNewDiskClick = () => {
    onAction();
    LocalStorage.set('storageParameters', {});
  };

  const onOfferClick = (offer: OfferType) => {
    LocalStorage.set('storageParameters', {
      ...offer,
      total_volume: offer.capacity,
    });
    onAction();
  };

  const onBusinessClick = () => {
    LocalStorage.set('businessSubscription', true);
    onAction();
  };

  return (
    <>
      {isVisible && (
        <div className={`full-modal ${isAnimating ? 'fade-out' : 'fade-in'}`}>
          <div className="full-modal__content">
            <div className="full-modal__header">
              <div className="full-modal__container">
                <div className="full-modal__header-wrapper">
                  <div className="full-modal__title">
                    <Logo />
                    <div className="split" />
                    <span>{t('disks')}</span>
                  </div>
                  <div className="full-modal__close" onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="full-modal__data full-height">
              <div className="full-modal__container">
                <div className="full-modal__body">
                  <div className="full-modal__disks full-modal__list">
                    <div className="full-modal__list-title">{t('freedoms')}</div>
                    <div className="full-modal__list-content">
                      <SubscriptionDisk
                        disk={
                          {
                            ...emptyDisk,
                            id: '00',
                            name: 'Free disk',
                            total_volume: 10000000000,
                            filled_volume: 0,
                          } as DiskType
                        }
                        title="Free disk"
                        content={
                          <>
                            <div className="creat-disk-obj__title">{t('freeDisk')}</div>
                            <div className="creat-disk-obj__subtitle">
                              {t('personalLibraryText')}
                            </div>
                            <div className="creat-disk-obj__description">
                              {t('signUpAndGet10Text')}
                            </div>
                          </>
                        }
                        btnText={t('signUp')}
                        onClick={onSignUp}
                      />
                      <CreatingDisk onCLick={onNewDiskClick} />
                    </div>
                  </div>
                  <div className="full-modal__offers full-modal__list">
                    <div className="full-modal__list-title">{t('storageOffers')}</div>
                    <div className="full-modal__list-content">
                      {getOffersList(t).map((offer, i) => {
                        return (
                          <div key={i}>
                            <OfferItem
                              offer={offer}
                              onClick={() => {
                                onOfferClick(offer);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="full-modal__subscriptions full-modal__list">
                    <div className="full-modal__list-title">{t('functionality')}</div>
                    <div className="full-modal__list-content">
                      <BusinessItem onCLick={onBusinessClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
