export const parametersOptions = (t: (str: string) => string) => ({
  diskParameters: [
    {
      label: t('standard'),
      value: 'standard',
      description: t('generalPurposeStandard'),
      functionsLink: 'Functions',
      readMoreLink: '',
      keyFeatures: [
        t('generalPurposeStorage'),
        t('lowLatencyHighThroughput'),
        t('storageAvailabilityAgreement'),
      ],
    },
    {
      label: t('highPerformanceShort'),
      value: 'performance',
      description: t('expressOneZone'),
      functionsLink: 'Functions',
      readMoreLink: '',
      keyFeatures: [
        t('highPerformance'),
        t('requestProcessing'),
        t('increaseAccessSpeed'),
        t('availabilityZone'),
        t('accelerateAnalytics'),
        t('scaleRequests'),
        t('optimizeDatasets'),
        t('leverageS3'),
        t('storageAvailability'),
      ],
    },
    {
      label: t('infrequentAccess'),
      value: 'infrequent_access',
      description: t('standardIA'),
      functionsLink: 'Functions',
      readMoreLink: '',
      keyFeatures: [
        t('rarelyUsedData'),
        t('lowLatencyHighThroughputS3'),
        t('storageAvailabilityAgreement99'),
      ],
    },
    {
      label: t('archiving'),
      value: 'archiving',
      description: t('glacierArchiving'),
      functionsLink: 'Functions',
      readMoreLink: '',
      keyFeatures: [],
    },
  ],
});

export const archivingOptions = (t: (str: string) => string) => [
  {
    label: t('flexibleRetrieval'),
    value: 'flexible_retrieval',
    description: t('retrievalSupport'),
    functionsLink: '',
    readMoreLink: 'Read More',
    keyFeatures: [
      t('longTermStorage'),
      t('retrieveMilliseconds'),
      t('storageAvailabilityAgreement992'),
      t('minimumObjectSize'),
      t('s3PutApi'),
    ],
  },
  {
    label: t('deepArchive'),
    value: 'deep_archive',
    description: t('minimalCloudCosts'),
    functionsLink: '',
    readMoreLink: 'Read More',
    keyFeatures: [
      t('lowCostBackupArchiving'),
      t('storageAvailabilityAgreement9999'),
      t('sslSupport'),
      t('backupDisasterRecovery'),
      t('customizableRetrievalTimes'),
      t('s3PutApiFlexibleRetrieval'),
    ],
  },
  {
    label: t('instantRetrieval'),
    value: 'instant_retrieval',
    description: t('minimizesLongTermStorageCost'),
    functionsLink: '',
    readMoreLink: 'Read More',
    keyFeatures: [
      t('rarelyUsedArchivalData'),
      t('repositoryAvailabilityAgreement9999'),
      t('idealAlternativeToTape'),
      t('retrievalTime12Hours'),
      t('s3PutApiDeepArchive'),
    ],
  },
];
