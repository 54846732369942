import { useQuery } from '@tanstack/react-query';
import { API } from 'src/utils/API';
import { NotificationType, OperationType } from 'src/types';

type NotificationsResponse = {
  success: boolean;
  notifications: NotificationType[];
};

export const useGetNotifications = (payload: { disabled?: boolean }) => {
  const { disabled } = payload;

  const fetchData = async (): Promise<NotificationsResponse> => {
    const resp = await API.get('/notifications/history', 'archive');
    if (resp.success) {
      return {
        success: true,
        notifications: resp.data
          .sort(
            (a: NotificationType, b: NotificationType) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          )
          .slice(0, 20),
      };
    } else {
      return { success: false, notifications: [] };
    }
  };

  const queryResult = useQuery({
    queryKey: ['get-notifications', payload],
    queryFn: fetchData,
    staleTime: 0,
    enabled: !disabled,
    retry: 0,
  });

  const invalidateCache = () => {
    queryResult.refetch();
  };
  return {
    ...queryResult,
    invalidateCache,
  };
};

type OperationsResponse = {
  success: boolean;
  operations: OperationType[]; //NotificationType[];
};

export const useGetOperations = (payload: { disabled?: boolean }) => {
  const { disabled } = payload;

  const fetchData = async (): Promise<OperationsResponse> => {
    const resp = await API.get('/operation/history', 'archive');
    if (resp.success) {
      return {
        success: true,
        operations: resp.data
          .sort(
            (a: OperationType, b: OperationType) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          )
          .slice(0, 20),
      };
    } else {
      return { success: false, operations: [] };
    }
  };

  const queryResult = useQuery({
    queryKey: ['get-operations', payload],
    queryFn: fetchData,
    staleTime: 0,
    enabled: !disabled,
    retry: 0,
  });

  const invalidateCache = () => {
    queryResult.refetch();
  };
  return {
    ...queryResult,
    invalidateCache,
  };
};
