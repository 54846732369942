import LocalStorage from 'src/utils/Storage';
import { convertBinaryToBase64, getBrowserFingerprint } from 'src/helpers';

export type RequestConfig = {
  baseURL: string;
  headers?: HeadersInit;
  protocol?: string;
  area?: string;
};

const url = process.env.REACT_APP_API_URL || 'http://localhost:3000';
const protocol = process.env.REACT_APP_API_PROTOCOL || 'http';

export const getAuthorizationHeader = (token: string) => {
  return `Bearer ${token}`;
};

export class API_Fetch {
  public static config: RequestConfig = {
    baseURL: url,
    protocol: protocol,
    area: 'auth',
    headers: {
      get Authorization() {
        return getAuthorizationHeader(LocalStorage.getToken());
      },
      'Content-Type': 'application/json',
      DEVICE_ID: getBrowserFingerprint(),
    },
  };

  static refreshAccessToken = async () => {
    const refreshToken = LocalStorage.getRefreshToken();

    const user_id = LocalStorage.getUserId();

    if (!refreshToken) {
      LocalStorage.logout();
    }

    const response = await fetch(
      `${this.config.protocol}://${this.config.area}.${this.config.baseURL}/refresh`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken, user_id }),
      },
    );

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const newSession = await response.json();
    LocalStorage.set('session', newSession);
    return newSession.access_token;
  };

  private static async requestWithRefresh(
    endpoint: string,
    options: RequestInit,
    area: string,
    retry: boolean = true,
  ): Promise<any> {
    try {
      const url = `${this.config.protocol}://${area}.${this.config.baseURL}${endpoint}`;
      const response = await fetch(url, options);
      //console.log(response);

      if (response.status === 401 && retry) {
        const newAccessToken = await this.refreshAccessToken();
        this.config = {
          ...this.config,
          headers: {
            ...this.config.headers,
            Authorization: `Bearer ${newAccessToken}`,
          },
        };

        options.headers = this.config.headers;
        return this.requestWithRefresh(endpoint, options, area, false);
      }

      return this.handleResponse(response);
    } catch (error: any) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return {
          success: false,
          error: 'aborted',
          rest: {},
        };
      }
      return {
        success: false,
        error: error.message || 'Something went wrong',
        rest: {},
      };
    }
  }

  public static async get(endpoint: string, area: string = 'auth', config = {}): Promise<any> {
    const options: RequestInit = {
      method: 'GET',
      headers: this.config.headers,
      ...config,
    };
    return this.requestWithRefresh(endpoint, options, area);
  }

  public static async getAbs(endpoint: string): Promise<any> {
    try {
      const options: RequestInit = {
        method: 'GET',
        headers: this.config.headers,
      };
      const response = await fetch(endpoint, options);
      return this.handleResponse(response);
    } catch (error: any) {
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        console.log('CORS issue or network error occurred');
      } else {
        console.log('An unexpected error occurred:', error);
      }
      return {
        success: false,
        error: error.message || 'Something went wrong',
      };
    }
  }

  public static async post(
    endpoint: string,
    body: any,
    area: string = 'auth',
    requestOptions?: any,
  ): Promise<any> {
    const { headers, ...rest } = requestOptions || {};
    const options: RequestInit = {
      method: 'POST',
      headers: {
        ...this.config.headers,
        ...headers,
      },
      ...rest,
    };
    if (body) {
      options.body = headers ? body : JSON.stringify(body);
    }
    return this.requestWithRefresh(endpoint, options, area);
  }

  public static async put(endpoint: string, body: any, area: string = 'auth'): Promise<any> {
    const options: RequestInit = {
      method: 'PUT',
      headers: this.config.headers,
      body: JSON.stringify(body),
    };
    return this.requestWithRefresh(endpoint, options, area);
  }

  public static async patch(endpoint: string, body: any, area: string = 'auth'): Promise<any> {
    const options: RequestInit = {
      method: 'PATCH',
      headers: this.config.headers,
      body: JSON.stringify(body),
    };
    return this.requestWithRefresh(endpoint, options, area);
  }

  public static async delete(endpoint: string, area: string = 'auth'): Promise<any> {
    const options: RequestInit = {
      method: 'DELETE',
      headers: this.config.headers,
    };
    return this.requestWithRefresh(endpoint, options, area);
  }

  private static async handleResponse(response: Response): Promise<any> {
    try {
      if (!response.ok) {
        const error = await response.json();
        return {
          success: false,
          error: error.message || 'Something went wrong',
          data: error,
        };
      }
      const contentType = response.headers.get('content-type');
      let data: any;
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else if (contentType && contentType.includes('text/plain')) {
        const arrayBuffer = await response.arrayBuffer();
        const binaryData = new Uint8Array(arrayBuffer);
        data = await convertBinaryToBase64(binaryData);
      } else if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let text = '';
        let done = false;
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          text += decoder.decode(value, { stream: !done });
        }
        try {
          data = JSON.parse(text);
        } catch (e) {
          data = text;
        }
      } else {
        data = null;
      }
      return {
        success: true,
        data,
      };
    } catch (e) {
      return {
        success: false,
        error: 'Something went wrong',
        data: {},
      };
    }
  }
}
