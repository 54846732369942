import React, { useEffect, useState } from 'react';
import Tab from './tab';
import { DiskType } from 'src/types';
import { ReactComponent as PlusIcon } from './assets/plus-icon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/Forms/Button';
import { TrashIcon } from 'src/components/Icons';
import './styles.scss';

interface TabsProps {
  disks: DiskType[];
  onTabClick?: (disk: DiskType) => void;
  onAddClick?: () => void;
  children?: React.ReactNode;
}

export const DiskTabs: React.FC<TabsProps> = ({ disks, onTabClick, onAddClick, children }) => {
  const navigate = useNavigate();
  const { diskId } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabClick = (disk: DiskType, index: number) => {
    setActiveIndex(index);
    if (onTabClick) {
      onTabClick(disk);
    }
  };

  const onAddClickHandler = () => {
    if (onAddClick) {
      onAddClick();
    }
  };

  useEffect(() => {
    if (diskId) {
      const diskIndex = disks.findIndex((disk) => disk.id === diskId);
      if (diskIndex !== -1) {
        setActiveIndex(diskIndex);
      }
    }
  }, [diskId, disks]);

  const onTrashClick = () => {
    navigate(`/trash/${diskId}`);
  };

  return (
    <div className="disk-tabs">
      <div className="disk-tabs__list">
        <div className="disk-tabs__list-content">
          {disks.map((disk, index) => (
            <Tab
              key={index}
              label={disk.name}
              active={index === activeIndex}
              disk={disk}
              onClick={() => handleTabClick(disk, index)}
            />
          ))}
          <div className="disk-tabs__add skip-outside" onClick={onAddClickHandler}>
            <PlusIcon className="skip-outside" />
          </div>
        </div>
        <div className="disk-tabs__btn-wrapper">
          <Button
            type="basic"
            content="Trash"
            icon={<TrashIcon />}
            className="disk-tabs__trash-btn"
            onClick={onTrashClick}
          />
        </div>
      </div>

      {/*<div className="disk-tabs__content">{children}</div>*/}
    </div>
  );
};
