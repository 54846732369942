import React, { FC } from 'react';
import { AddToFolderIcon, DownloadIcon } from 'src/components/Icons/context';
import { DeleteIcon } from 'src/components/Icons';
import { useFolderState } from 'src/pages/diskPage/hooks/useFolderState';

type Props = {
  item: any;
};
export const FolderActions: FC<Props> = ({ item }) => {
  const { onMoveFolder, onDeleteFolder } = useFolderState(item);

  return (
    <>
      <div className="table-btn-actions">
        <div
          onClick={(e) => {
            e.stopPropagation();
            // onRestoreClick(item);
          }}
        >
          <DownloadIcon className="dropdown-svg-icon" />
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation();
            onMoveFolder(item);
          }}
        >
          <AddToFolderIcon className="dropdown-svg-icon" />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDeleteFolder(item);
          }}
        >
          <DeleteIcon />
        </div>
      </div>
    </>
  );
};
