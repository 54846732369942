import React, { useState } from 'react';
import moment from 'moment';
import { ReactComponent as WalletIcon } from '../../assets/wallet-icon.svg';
import { ArrowTopIcon } from 'src/components/Icons';
import classNames from 'classnames';
import { ReactComponent as CloudIcon } from '../../assets/cloud-icon.svg';
import './styles.scss';
import 'moment/locale/ru';
import 'moment/locale/fr';
import 'moment/locale/uk';
import { useTranslation } from 'react-i18next';
import { useGetOperations } from 'src/api/notifications';
import { OperationType } from 'src/types';
import { get } from 'lodash';

// Helper function to group notifications by date
const groupNotificationsByDate = (notifications: OperationType[]) => {
  return notifications.reduce((groups: { [key: string]: OperationType[] }, notification) => {
    const date = moment.utc(notification.created_at).format('YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(notification);
    return groups;
  }, {});
};

export const Operations: React.FC = () => {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);

  const { data } = useGetOperations({ disabled: false });
  const operations: OperationType[] = get(data, 'operations', []);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true); // Collapsed by default
  const groupedNotifications = groupNotificationsByDate(operations);
  const flatNotifications = Object.keys(groupedNotifications).reduce(
    (acc: { date: string; notifications: OperationType[] }[], date: string) => {
      acc.push({
        date,
        notifications: groupedNotifications[date],
      });
      return acc;
    },
    [],
  );

  const getVisibleNotifications = () => {
    if (isCollapsed) {
      let count = 0;
      const visibleNotifications = flatNotifications.map((group) => {
        const visibleItems = group.notifications.slice(0, Math.max(0, 4 - count));
        count += visibleItems.length;
        return {
          ...group,
          notifications: visibleItems,
        };
      });
      return visibleNotifications.filter((group) => group.notifications.length > 0);
    }
    return flatNotifications;
  };

  return (
    <div className="operations">
      <div className="operations__title">
        <div className="operations__title--content">
          <WalletIcon className="operations__title-icon" />
          <span>{t('recentOperations')}</span>
        </div>

        <div className={classNames('operations__toggle', { reverted: isCollapsed })}>
          <ArrowTopIcon onClick={() => setIsCollapsed(!isCollapsed)} />
        </div>
      </div>

      <div className="operations__content">
        {getVisibleNotifications().map((group) => (
          <div key={group.date}>
            <div className="operations__date">
              {moment.utc(group.date).calendar(null, {
                sameDay: `[${t('today')}]`,
                lastDay: `[${t('yesterday')}]`,
                lastWeek: 'dddd',
                sameElse: 'DD MMMM YYYY',
              })}
            </div>
            {group.notifications.map((notification: OperationType) => (
              <div key={notification.id} className="operations__row">
                <div className="operations__item">
                  <div className="operations__icon">
                    <CloudIcon />
                  </div>
                  <div className="operations__item-content">
                    <div className="operations__item-title">{notification.title}</div>
                    <div className="operations__item-message">{notification.subtitle}</div>
                  </div>
                  <div className="operations__item-time">
                    {/*<span className="highlighted">10GB</span>*/}
                    <span>{moment.utc(notification.created_at).format('HH:mm')}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
