import React, { FC } from 'react';
import { Button } from 'src/components/Forms/Button';
import { useTranslation } from 'react-i18next';
import { AnimatedClip } from 'src/components/AnimatedClip';
import './styles.scss';

type Props = {
  onCLose: () => void;
  onRepeat: () => void;
};

export const FailedModal: FC<Props> = ({ onCLose, onRepeat }) => {
  const { t } = useTranslation();

  return (
    <div className="notification-modal failed">
      <div className="notification-modal__animation">
        <AnimatedClip type="idn" className="notification-modal__clip" />
      </div>
      <h3 className="notification-modal__title">{t('paymentFailed')}</h3>
      <p className="notification-modal__text">{t('failedPayment')}</p>
      <div className="notification-modal__btn-wrapper">
        <Button
          type="basic-danger"
          className="notification-modal__btn"
          content={t('cancel')}
          onClick={onCLose}
        />
        <Button
          type="basic"
          className="notification-modal__btn"
          content={t('repeat')}
          onClick={onRepeat}
        />
      </div>
    </div>
  );
};
